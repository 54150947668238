import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _299b24fc = () => interopDefault(import('../pages/daftar-implementasi-pesantren/index.vue' /* webpackChunkName: "pages/daftar-implementasi-pesantren/index" */))
const _2e7d20e8 = () => interopDefault(import('../pages/login-history/index.vue' /* webpackChunkName: "pages/login-history/index" */))
const _a63aaa1e = () => interopDefault(import('../pages/ppob/index.vue' /* webpackChunkName: "pages/ppob/index" */))
const _70cb3a3e = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _263235a1 = () => interopDefault(import('../pages/read-more.vue' /* webpackChunkName: "pages/read-more" */))
const _1ec5e3ae = () => interopDefault(import('../pages/santri/index.vue' /* webpackChunkName: "pages/santri/index" */))
const _a1314b0c = () => interopDefault(import('../pages/admin/home.vue' /* webpackChunkName: "pages/admin/home" */))
const _561d1a61 = () => interopDefault(import('../pages/admin/profile/index.vue' /* webpackChunkName: "pages/admin/profile/index" */))
const _f221ecc6 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _2160ce1f = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _e8d5c39a = () => interopDefault(import('../pages/daftar-implementasi-pesantren/success.vue' /* webpackChunkName: "pages/daftar-implementasi-pesantren/success" */))
const _1ff7a842 = () => interopDefault(import('../pages/host/midtrans/index.vue' /* webpackChunkName: "pages/host/midtrans/index" */))
const _b406e8c2 = () => interopDefault(import('../pages/host/otto/index.vue' /* webpackChunkName: "pages/host/otto/index" */))
const _9ec0fb48 = () => interopDefault(import('../pages/host/pay.vue' /* webpackChunkName: "pages/host/pay" */))
const _6e497254 = () => interopDefault(import('../pages/login-history/create.vue' /* webpackChunkName: "pages/login-history/create" */))
const _2e40e20c = () => interopDefault(import('../pages/page/cek-status-booking/index.vue' /* webpackChunkName: "pages/page/cek-status-booking/index" */))
const _30f172e6 = () => interopDefault(import('../pages/page/kontak-kami.vue' /* webpackChunkName: "pages/page/kontak-kami" */))
const _5b9fd146 = () => interopDefault(import('../pages/page/peta-situs.vue' /* webpackChunkName: "pages/page/peta-situs" */))
const _2cdeb94c = () => interopDefault(import('../pages/page/privacy-policy.vue' /* webpackChunkName: "pages/page/privacy-policy" */))
const _257d7971 = () => interopDefault(import('../pages/page/tentang-kami.vue' /* webpackChunkName: "pages/page/tentang-kami" */))
const _31fa412a = () => interopDefault(import('../pages/ppob/type.vue' /* webpackChunkName: "pages/ppob/type" */))
const _91480216 = () => interopDefault(import('../pages/santri/profil.vue' /* webpackChunkName: "pages/santri/profil" */))
const _3e94e6f8 = () => interopDefault(import('../pages/admin/core/akun-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/index" */))
const _c61523ce = () => interopDefault(import('../pages/admin/core/buku-besar/index.vue' /* webpackChunkName: "pages/admin/core/buku-besar/index" */))
const _00c1fe42 = () => interopDefault(import('../pages/admin/core/jurnal-khusus/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-khusus/index" */))
const _6f5250e9 = () => interopDefault(import('../pages/admin/core/jurnal-penyesuaian/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-penyesuaian/index" */))
const _6fac48ce = () => interopDefault(import('../pages/admin/core/jurnal-umum/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/index" */))
const _20a98578 = () => interopDefault(import('../pages/admin/core/kertas-kerja/index.vue' /* webpackChunkName: "pages/admin/core/kertas-kerja/index" */))
const _1e9f03cc = () => interopDefault(import('../pages/admin/core/laba-rugi/index.vue' /* webpackChunkName: "pages/admin/core/laba-rugi/index" */))
const _4ffb8754 = () => interopDefault(import('../pages/admin/core/laporan-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/laporan-keuangan/index" */))
const _8456a428 = () => interopDefault(import('../pages/admin/core/neraca/index.vue' /* webpackChunkName: "pages/admin/core/neraca/index" */))
const _277dc7a0 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/index.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/index" */))
const _52e14e06 = () => interopDefault(import('../pages/admin/customer-service/nasabah/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/index" */))
const _66b1d716 = () => interopDefault(import('../pages/admin/customer-service/nominatif/index.vue' /* webpackChunkName: "pages/admin/customer-service/nominatif/index" */))
const _36d94cc8 = () => interopDefault(import('../pages/admin/customer-service/registrasi/index.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/index" */))
const _2c08fe60 = () => interopDefault(import('../pages/admin/customer-service/rekening-koran/index.vue' /* webpackChunkName: "pages/admin/customer-service/rekening-koran/index" */))
const _0259f5a8 = () => interopDefault(import('../pages/admin/customer-service/rekening-wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/rekening-wali/index" */))
const _3812714e = () => interopDefault(import('../pages/admin/customer-service/riwayat-transfer/index.vue' /* webpackChunkName: "pages/admin/customer-service/riwayat-transfer/index" */))
const _2e467161 = () => interopDefault(import('../pages/admin/customer-service/tagihan-spp/index.vue' /* webpackChunkName: "pages/admin/customer-service/tagihan-spp/index" */))
const _ded5c806 = () => interopDefault(import('../pages/admin/customer-service/transaksi/index.vue' /* webpackChunkName: "pages/admin/customer-service/transaksi/index" */))
const _52311fa8 = () => interopDefault(import('../pages/admin/customer-service/wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/index" */))
const _fe98e7fe = () => interopDefault(import('../pages/admin/master/bank/index.vue' /* webpackChunkName: "pages/admin/master/bank/index" */))
const _0e131481 = () => interopDefault(import('../pages/admin/master/bcn-bank/index.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/index" */))
const _7b275e5d = () => interopDefault(import('../pages/admin/master/document-type/index.vue' /* webpackChunkName: "pages/admin/master/document-type/index" */))
const _d999d304 = () => interopDefault(import('../pages/admin/master/jenis-kas/index.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/index" */))
const _6ee483e3 = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/index.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/index" */))
const _5f5dc8d6 = () => interopDefault(import('../pages/admin/master/jenis-rekening/index.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/index" */))
const _36a05f30 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/index.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/index" */))
const _319dae5e = () => interopDefault(import('../pages/admin/master/lembaga/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/index" */))
const _42a7a7ea = () => interopDefault(import('../pages/admin/master/lembaga-booking/index.vue' /* webpackChunkName: "pages/admin/master/lembaga-booking/index" */))
const _84ed0050 = () => interopDefault(import('../pages/admin/master/news/index.vue' /* webpackChunkName: "pages/admin/master/news/index" */))
const _5fdfcd2e = () => interopDefault(import('../pages/admin/master/news-category/index.vue' /* webpackChunkName: "pages/admin/master/news-category/index" */))
const _8b8bfb9c = () => interopDefault(import('../pages/admin/master/produk/index.vue' /* webpackChunkName: "pages/admin/master/produk/index" */))
const _5145f7a8 = () => interopDefault(import('../pages/admin/master/setting-institution/index.vue' /* webpackChunkName: "pages/admin/master/setting-institution/index" */))
const _6181434e = () => interopDefault(import('../pages/admin/master/transaction-type/index.vue' /* webpackChunkName: "pages/admin/master/transaction-type/index" */))
const _75ba098d = () => interopDefault(import('../pages/admin/master/vendor/index.vue' /* webpackChunkName: "pages/admin/master/vendor/index" */))
const _4022dd0f = () => interopDefault(import('../pages/admin/pengaturan/management-user/index.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/index" */))
const _2db239ab = () => interopDefault(import('../pages/admin/pengaturan/permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/index" */))
const _14270452 = () => interopDefault(import('../pages/admin/pengaturan/role/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role/index" */))
const _c936f2da = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/index" */))
const _97243432 = () => interopDefault(import('../pages/admin/profile/change-password.vue' /* webpackChunkName: "pages/admin/profile/change-password" */))
const _dfdeb822 = () => interopDefault(import('../pages/admin/transaksi/monitoring-admin/index.vue' /* webpackChunkName: "pages/admin/transaksi/monitoring-admin/index" */))
const _968cc3ac = () => interopDefault(import('../pages/admin/transaksi/penarikan/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/index" */))
const _0245250e = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/index" */))
const _2f16a52b = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/index.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/index" */))
const _36f5b59e = () => interopDefault(import('../pages/host/flip/accept.vue' /* webpackChunkName: "pages/host/flip/accept" */))
const _cb4ff0fe = () => interopDefault(import('../pages/host/flip/transfer.vue' /* webpackChunkName: "pages/host/flip/transfer" */))
const _5db04eba = () => interopDefault(import('../pages/admin/core/akun-keuangan/create.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/create" */))
const _64c5e245 = () => interopDefault(import('../pages/admin/core/jurnal-umum/create.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/create" */))
const _0dae8ac2 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/index.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/index" */))
const _23101161 = () => interopDefault(import('../pages/admin/customer-service/nasabah/create.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/create" */))
const _44292fce = () => interopDefault(import('../pages/admin/customer-service/nasabah/export.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/export" */))
const _ac655af4 = () => interopDefault(import('../pages/admin/customer-service/nasabah/qr.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/qr" */))
const _0fb69da9 = () => interopDefault(import('../pages/admin/customer-service/nasabah/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/rekening/index" */))
const _7a131834 = () => interopDefault(import('../pages/admin/customer-service/nasabah/upload.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/upload" */))
const _54e7b6bc = () => interopDefault(import('../pages/admin/customer-service/registrasi/create.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/create" */))
const _1ba72016 = () => interopDefault(import('../pages/admin/customer-service/wali/create.vue' /* webpackChunkName: "pages/admin/customer-service/wali/create" */))
const _13c2275e = () => interopDefault(import('../pages/admin/customer-service/wali/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/rekening/index" */))
const _851b8246 = () => interopDefault(import('../pages/admin/master/bank/create.vue' /* webpackChunkName: "pages/admin/master/bank/create" */))
const _47f87346 = () => interopDefault(import('../pages/admin/master/bcn-bank/create.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/create" */))
const _1178b801 = () => interopDefault(import('../pages/admin/master/document-type/create.vue' /* webpackChunkName: "pages/admin/master/document-type/create" */))
const _0a37f800 = () => interopDefault(import('../pages/admin/master/jenis-kas/create.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/create" */))
const _d53f798a = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/create.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/create" */))
const _97e0c6b0 = () => interopDefault(import('../pages/admin/master/jenis-rekening/create.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/create" */))
const _4e02f154 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/create.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/create" */))
const _963e3658 = () => interopDefault(import('../pages/admin/master/lembaga/bcn-code/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/bcn-code/index" */))
const _29cc6820 = () => interopDefault(import('../pages/admin/master/lembaga/create.vue' /* webpackChunkName: "pages/admin/master/lembaga/create" */))
const _59a75e75 = () => interopDefault(import('../pages/admin/master/news-category/create.vue' /* webpackChunkName: "pages/admin/master/news-category/create" */))
const _1b5ac5e6 = () => interopDefault(import('../pages/admin/master/news/create.vue' /* webpackChunkName: "pages/admin/master/news/create" */))
const _968ae268 = () => interopDefault(import('../pages/admin/master/produk/create.vue' /* webpackChunkName: "pages/admin/master/produk/create" */))
const _13491da0 = () => interopDefault(import('../pages/admin/master/transaction-type/create.vue' /* webpackChunkName: "pages/admin/master/transaction-type/create" */))
const _693b72d1 = () => interopDefault(import('../pages/admin/master/vendor/create.vue' /* webpackChunkName: "pages/admin/master/vendor/create" */))
const _2825e0e2 = () => interopDefault(import('../pages/admin/pengaturan/management-user/create.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/create" */))
const _9f6d731a = () => interopDefault(import('../pages/admin/pengaturan/permission/create.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/create" */))
const _92282562 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product/index.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product/index" */))
const _5df4eba5 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-detail/index.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-detail/index" */))
const _3b479d8e = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-type/index.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-type/index" */))
const _cf245ea8 = () => interopDefault(import('../pages/admin/pengaturan/role/create.vue' /* webpackChunkName: "pages/admin/pengaturan/role/create" */))
const _04848c65 = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/create" */))
const _0a2e71d4 = () => interopDefault(import('../pages/admin/transaksi/penarikan/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/create" */))
const _491b6a1a = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/create.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/create" */))
const _53a2e3ff = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/create.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/create" */))
const _ef46588e = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-detail/create.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-detail/create" */))
const _55605ef0 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-type/create.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-type/create" */))
const _4e46074f = () => interopDefault(import('../pages/admin/pengaturan/ppob/product/create.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product/create" */))
const _474f00c7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2292d8c7 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/_id.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/_id" */))
const _1256affb = () => interopDefault(import('../pages/admin/master/produk/custom-nominal/_id.vue' /* webpackChunkName: "pages/admin/master/produk/custom-nominal/_id" */))
const _2691870b = () => interopDefault(import('../pages/admin/master/vendor/user/_id.vue' /* webpackChunkName: "pages/admin/master/vendor/user/_id" */))
const _6eb8d966 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-detail/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-detail/_id" */))
const _f8c8a914 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-type/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-type/_id" */))
const _183a1112 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product/_id" */))
const _04619729 = () => interopDefault(import('../pages/admin/master/vendor/user/_vendor_id/create.vue' /* webpackChunkName: "pages/admin/master/vendor/user/_vendor_id/create" */))
const _3e84dfec = () => interopDefault(import('../pages/admin/core/akun-keuangan/_id.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/_id" */))
const _69ad12b6 = () => interopDefault(import('../pages/admin/customer-service/nasabah/_id.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/_id" */))
const _a95a27e0 = () => interopDefault(import('../pages/admin/customer-service/wali/_id.vue' /* webpackChunkName: "pages/admin/customer-service/wali/_id" */))
const _38069aa9 = () => interopDefault(import('../pages/admin/master/bank/_id.vue' /* webpackChunkName: "pages/admin/master/bank/_id" */))
const _50ae4329 = () => interopDefault(import('../pages/admin/master/bcn-bank/_id.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/_id" */))
const _227957f6 = () => interopDefault(import('../pages/admin/master/document-type/_id.vue' /* webpackChunkName: "pages/admin/master/document-type/_id" */))
const _461ebf34 = () => interopDefault(import('../pages/admin/master/jenis-kas/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/_id" */))
const _7c78230b = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/_id" */))
const _1757ee84 = () => interopDefault(import('../pages/admin/master/jenis-rekening/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/_id" */))
const _6b46ccd0 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/_id" */))
const _3adfb046 = () => interopDefault(import('../pages/admin/master/lembaga/_id.vue' /* webpackChunkName: "pages/admin/master/lembaga/_id" */))
const _3f113bde = () => interopDefault(import('../pages/admin/master/news-category/_id.vue' /* webpackChunkName: "pages/admin/master/news-category/_id" */))
const _20a59840 = () => interopDefault(import('../pages/admin/master/news/_id.vue' /* webpackChunkName: "pages/admin/master/news/_id" */))
const _0866e91a = () => interopDefault(import('../pages/admin/master/produk/_id.vue' /* webpackChunkName: "pages/admin/master/produk/_id" */))
const _2713bcd8 = () => interopDefault(import('../pages/admin/master/setting-institution/_id.vue' /* webpackChunkName: "pages/admin/master/setting-institution/_id" */))
const _0383c136 = () => interopDefault(import('../pages/admin/master/transaction-type/_id.vue' /* webpackChunkName: "pages/admin/master/transaction-type/_id" */))
const _f931e996 = () => interopDefault(import('../pages/admin/master/vendor/_id.vue' /* webpackChunkName: "pages/admin/master/vendor/_id" */))
const _2992d737 = () => interopDefault(import('../pages/admin/pengaturan/management-user/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/_id" */))
const _2d48aad3 = () => interopDefault(import('../pages/admin/pengaturan/permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/_id" */))
const _350ddc8a = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/_id" */))
const _27edf33a = () => interopDefault(import('../pages/admin/pengaturan/role/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role/_id" */))
const _1b1907e2 = () => interopDefault(import('../pages/page/cek-status-booking/_id.vue' /* webpackChunkName: "pages/page/cek-status-booking/_id" */))
const _cc3a02b4 = () => interopDefault(import('../pages/auth/_registerBak.vue' /* webpackChunkName: "pages/auth/_registerBak" */))
const _dda38560 = () => interopDefault(import('../pages/login-history/_id.vue' /* webpackChunkName: "pages/login-history/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/daftar-implementasi-pesantren",
    component: _299b24fc,
    name: "daftar-implementasi-pesantren"
  }, {
    path: "/login-history",
    component: _2e7d20e8,
    name: "login-history"
  }, {
    path: "/ppob",
    component: _a63aaa1e,
    name: "ppob"
  }, {
    path: "/profile",
    component: _70cb3a3e,
    name: "profile"
  }, {
    path: "/read-more",
    component: _263235a1,
    name: "read-more"
  }, {
    path: "/santri",
    component: _1ec5e3ae,
    name: "santri"
  }, {
    path: "/admin/home",
    component: _a1314b0c,
    name: "admin-home"
  }, {
    path: "/admin/profile",
    component: _561d1a61,
    name: "admin-profile"
  }, {
    path: "/auth/login",
    component: _f221ecc6,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _2160ce1f,
    name: "auth-register"
  }, {
    path: "/daftar-implementasi-pesantren/success",
    component: _e8d5c39a,
    name: "daftar-implementasi-pesantren-success"
  }, {
    path: "/host/midtrans",
    component: _1ff7a842,
    name: "host-midtrans"
  }, {
    path: "/host/otto",
    component: _b406e8c2,
    name: "host-otto"
  }, {
    path: "/host/pay",
    component: _9ec0fb48,
    name: "host-pay"
  }, {
    path: "/login-history/create",
    component: _6e497254,
    name: "login-history-create"
  }, {
    path: "/page/cek-status-booking",
    component: _2e40e20c,
    name: "page-cek-status-booking"
  }, {
    path: "/page/kontak-kami",
    component: _30f172e6,
    name: "page-kontak-kami"
  }, {
    path: "/page/peta-situs",
    component: _5b9fd146,
    name: "page-peta-situs"
  }, {
    path: "/page/privacy-policy",
    component: _2cdeb94c,
    name: "page-privacy-policy"
  }, {
    path: "/page/tentang-kami",
    component: _257d7971,
    name: "page-tentang-kami"
  }, {
    path: "/ppob/type",
    component: _31fa412a,
    name: "ppob-type"
  }, {
    path: "/santri/profil",
    component: _91480216,
    name: "santri-profil"
  }, {
    path: "/admin/core/akun-keuangan",
    component: _3e94e6f8,
    name: "admin-core-akun-keuangan"
  }, {
    path: "/admin/core/buku-besar",
    component: _c61523ce,
    name: "admin-core-buku-besar"
  }, {
    path: "/admin/core/jurnal-khusus",
    component: _00c1fe42,
    name: "admin-core-jurnal-khusus"
  }, {
    path: "/admin/core/jurnal-penyesuaian",
    component: _6f5250e9,
    name: "admin-core-jurnal-penyesuaian"
  }, {
    path: "/admin/core/jurnal-umum",
    component: _6fac48ce,
    name: "admin-core-jurnal-umum"
  }, {
    path: "/admin/core/kertas-kerja",
    component: _20a98578,
    name: "admin-core-kertas-kerja"
  }, {
    path: "/admin/core/laba-rugi",
    component: _1e9f03cc,
    name: "admin-core-laba-rugi"
  }, {
    path: "/admin/core/laporan-keuangan",
    component: _4ffb8754,
    name: "admin-core-laporan-keuangan"
  }, {
    path: "/admin/core/neraca",
    component: _8456a428,
    name: "admin-core-neraca"
  }, {
    path: "/admin/customer-service/blast-wa",
    component: _277dc7a0,
    name: "admin-customer-service-blast-wa"
  }, {
    path: "/admin/customer-service/nasabah",
    component: _52e14e06,
    name: "admin-customer-service-nasabah"
  }, {
    path: "/admin/customer-service/nominatif",
    component: _66b1d716,
    name: "admin-customer-service-nominatif"
  }, {
    path: "/admin/customer-service/registrasi",
    component: _36d94cc8,
    name: "admin-customer-service-registrasi"
  }, {
    path: "/admin/customer-service/rekening-koran",
    component: _2c08fe60,
    name: "admin-customer-service-rekening-koran"
  }, {
    path: "/admin/customer-service/rekening-wali",
    component: _0259f5a8,
    name: "admin-customer-service-rekening-wali"
  }, {
    path: "/admin/customer-service/riwayat-transfer",
    component: _3812714e,
    name: "admin-customer-service-riwayat-transfer"
  }, {
    path: "/admin/customer-service/tagihan-spp",
    component: _2e467161,
    name: "admin-customer-service-tagihan-spp"
  }, {
    path: "/admin/customer-service/transaksi",
    component: _ded5c806,
    name: "admin-customer-service-transaksi"
  }, {
    path: "/admin/customer-service/wali",
    component: _52311fa8,
    name: "admin-customer-service-wali"
  }, {
    path: "/admin/master/bank",
    component: _fe98e7fe,
    name: "admin-master-bank"
  }, {
    path: "/admin/master/bcn-bank",
    component: _0e131481,
    name: "admin-master-bcn-bank"
  }, {
    path: "/admin/master/document-type",
    component: _7b275e5d,
    name: "admin-master-document-type"
  }, {
    path: "/admin/master/jenis-kas",
    component: _d999d304,
    name: "admin-master-jenis-kas"
  }, {
    path: "/admin/master/jenis-pinjaman",
    component: _6ee483e3,
    name: "admin-master-jenis-pinjaman"
  }, {
    path: "/admin/master/jenis-rekening",
    component: _5f5dc8d6,
    name: "admin-master-jenis-rekening"
  }, {
    path: "/admin/master/jenis-simpanan",
    component: _36a05f30,
    name: "admin-master-jenis-simpanan"
  }, {
    path: "/admin/master/lembaga",
    component: _319dae5e,
    name: "admin-master-lembaga"
  }, {
    path: "/admin/master/lembaga-booking",
    component: _42a7a7ea,
    name: "admin-master-lembaga-booking"
  }, {
    path: "/admin/master/news",
    component: _84ed0050,
    name: "admin-master-news"
  }, {
    path: "/admin/master/news-category",
    component: _5fdfcd2e,
    name: "admin-master-news-category"
  }, {
    path: "/admin/master/produk",
    component: _8b8bfb9c,
    name: "admin-master-produk"
  }, {
    path: "/admin/master/setting-institution",
    component: _5145f7a8,
    name: "admin-master-setting-institution"
  }, {
    path: "/admin/master/transaction-type",
    component: _6181434e,
    name: "admin-master-transaction-type"
  }, {
    path: "/admin/master/vendor",
    component: _75ba098d,
    name: "admin-master-vendor"
  }, {
    path: "/admin/pengaturan/management-user",
    component: _4022dd0f,
    name: "admin-pengaturan-management-user"
  }, {
    path: "/admin/pengaturan/permission",
    component: _2db239ab,
    name: "admin-pengaturan-permission"
  }, {
    path: "/admin/pengaturan/role",
    component: _14270452,
    name: "admin-pengaturan-role"
  }, {
    path: "/admin/pengaturan/role-has-permission",
    component: _c936f2da,
    name: "admin-pengaturan-role-has-permission"
  }, {
    path: "/admin/profile/change-password",
    component: _97243432,
    name: "admin-profile-change-password"
  }, {
    path: "/admin/transaksi/monitoring-admin",
    component: _dfdeb822,
    name: "admin-transaksi-monitoring-admin"
  }, {
    path: "/admin/transaksi/penarikan",
    component: _968cc3ac,
    name: "admin-transaksi-penarikan"
  }, {
    path: "/admin/transaksi/penarikan-otomatis",
    component: _0245250e,
    name: "admin-transaksi-penarikan-otomatis"
  }, {
    path: "/admin/transaksi/setoran-tunai",
    component: _2f16a52b,
    name: "admin-transaksi-setoran-tunai"
  }, {
    path: "/host/flip/accept",
    component: _36f5b59e,
    name: "host-flip-accept"
  }, {
    path: "/host/flip/transfer",
    component: _cb4ff0fe,
    name: "host-flip-transfer"
  }, {
    path: "/admin/core/akun-keuangan/create",
    component: _5db04eba,
    name: "admin-core-akun-keuangan-create"
  }, {
    path: "/admin/core/jurnal-umum/create",
    component: _64c5e245,
    name: "admin-core-jurnal-umum-create"
  }, {
    path: "/admin/customer-service/blast-wa/template",
    component: _0dae8ac2,
    name: "admin-customer-service-blast-wa-template"
  }, {
    path: "/admin/customer-service/nasabah/create",
    component: _23101161,
    name: "admin-customer-service-nasabah-create"
  }, {
    path: "/admin/customer-service/nasabah/export",
    component: _44292fce,
    name: "admin-customer-service-nasabah-export"
  }, {
    path: "/admin/customer-service/nasabah/qr",
    component: _ac655af4,
    name: "admin-customer-service-nasabah-qr"
  }, {
    path: "/admin/customer-service/nasabah/rekening",
    component: _0fb69da9,
    name: "admin-customer-service-nasabah-rekening"
  }, {
    path: "/admin/customer-service/nasabah/upload",
    component: _7a131834,
    name: "admin-customer-service-nasabah-upload"
  }, {
    path: "/admin/customer-service/registrasi/create",
    component: _54e7b6bc,
    name: "admin-customer-service-registrasi-create"
  }, {
    path: "/admin/customer-service/wali/create",
    component: _1ba72016,
    name: "admin-customer-service-wali-create"
  }, {
    path: "/admin/customer-service/wali/rekening",
    component: _13c2275e,
    name: "admin-customer-service-wali-rekening"
  }, {
    path: "/admin/master/bank/create",
    component: _851b8246,
    name: "admin-master-bank-create"
  }, {
    path: "/admin/master/bcn-bank/create",
    component: _47f87346,
    name: "admin-master-bcn-bank-create"
  }, {
    path: "/admin/master/document-type/create",
    component: _1178b801,
    name: "admin-master-document-type-create"
  }, {
    path: "/admin/master/jenis-kas/create",
    component: _0a37f800,
    name: "admin-master-jenis-kas-create"
  }, {
    path: "/admin/master/jenis-pinjaman/create",
    component: _d53f798a,
    name: "admin-master-jenis-pinjaman-create"
  }, {
    path: "/admin/master/jenis-rekening/create",
    component: _97e0c6b0,
    name: "admin-master-jenis-rekening-create"
  }, {
    path: "/admin/master/jenis-simpanan/create",
    component: _4e02f154,
    name: "admin-master-jenis-simpanan-create"
  }, {
    path: "/admin/master/lembaga/bcn-code",
    component: _963e3658,
    name: "admin-master-lembaga-bcn-code"
  }, {
    path: "/admin/master/lembaga/create",
    component: _29cc6820,
    name: "admin-master-lembaga-create"
  }, {
    path: "/admin/master/news-category/create",
    component: _59a75e75,
    name: "admin-master-news-category-create"
  }, {
    path: "/admin/master/news/create",
    component: _1b5ac5e6,
    name: "admin-master-news-create"
  }, {
    path: "/admin/master/produk/create",
    component: _968ae268,
    name: "admin-master-produk-create"
  }, {
    path: "/admin/master/transaction-type/create",
    component: _13491da0,
    name: "admin-master-transaction-type-create"
  }, {
    path: "/admin/master/vendor/create",
    component: _693b72d1,
    name: "admin-master-vendor-create"
  }, {
    path: "/admin/pengaturan/management-user/create",
    component: _2825e0e2,
    name: "admin-pengaturan-management-user-create"
  }, {
    path: "/admin/pengaturan/permission/create",
    component: _9f6d731a,
    name: "admin-pengaturan-permission-create"
  }, {
    path: "/admin/pengaturan/ppob/product",
    component: _92282562,
    name: "admin-pengaturan-ppob-product"
  }, {
    path: "/admin/pengaturan/ppob/product-detail",
    component: _5df4eba5,
    name: "admin-pengaturan-ppob-product-detail"
  }, {
    path: "/admin/pengaturan/ppob/product-type",
    component: _3b479d8e,
    name: "admin-pengaturan-ppob-product-type"
  }, {
    path: "/admin/pengaturan/role/create",
    component: _cf245ea8,
    name: "admin-pengaturan-role-create"
  }, {
    path: "/admin/transaksi/penarikan-otomatis/create",
    component: _04848c65,
    name: "admin-transaksi-penarikan-otomatis-create"
  }, {
    path: "/admin/transaksi/penarikan/create",
    component: _0a2e71d4,
    name: "admin-transaksi-penarikan-create"
  }, {
    path: "/admin/transaksi/setoran-tunai/create",
    component: _491b6a1a,
    name: "admin-transaksi-setoran-tunai-create"
  }, {
    path: "/admin/customer-service/blast-wa/template/create",
    component: _53a2e3ff,
    name: "admin-customer-service-blast-wa-template-create"
  }, {
    path: "/admin/pengaturan/ppob/product-detail/create",
    component: _ef46588e,
    name: "admin-pengaturan-ppob-product-detail-create"
  }, {
    path: "/admin/pengaturan/ppob/product-type/create",
    component: _55605ef0,
    name: "admin-pengaturan-ppob-product-type-create"
  }, {
    path: "/admin/pengaturan/ppob/product/create",
    component: _4e46074f,
    name: "admin-pengaturan-ppob-product-create"
  }, {
    path: "/",
    component: _474f00c7,
    name: "index"
  }, {
    path: "/admin/customer-service/blast-wa/template/:id?",
    component: _2292d8c7,
    name: "admin-customer-service-blast-wa-template-id"
  }, {
    path: "/admin/master/produk/custom-nominal/:id?",
    component: _1256affb,
    name: "admin-master-produk-custom-nominal-id"
  }, {
    path: "/admin/master/vendor/user/:id?",
    component: _2691870b,
    name: "admin-master-vendor-user-id"
  }, {
    path: "/admin/pengaturan/ppob/product-detail/:id",
    component: _6eb8d966,
    name: "admin-pengaturan-ppob-product-detail-id"
  }, {
    path: "/admin/pengaturan/ppob/product-type/:id",
    component: _f8c8a914,
    name: "admin-pengaturan-ppob-product-type-id"
  }, {
    path: "/admin/pengaturan/ppob/product/:id",
    component: _183a1112,
    name: "admin-pengaturan-ppob-product-id"
  }, {
    path: "/admin/master/vendor/user/:vendor_id?/create",
    component: _04619729,
    name: "admin-master-vendor-user-vendor_id-create"
  }, {
    path: "/admin/core/akun-keuangan/:id?",
    component: _3e84dfec,
    name: "admin-core-akun-keuangan-id"
  }, {
    path: "/admin/customer-service/nasabah/:id?",
    component: _69ad12b6,
    name: "admin-customer-service-nasabah-id"
  }, {
    path: "/admin/customer-service/wali/:id?",
    component: _a95a27e0,
    name: "admin-customer-service-wali-id"
  }, {
    path: "/admin/master/bank/:id",
    component: _38069aa9,
    name: "admin-master-bank-id"
  }, {
    path: "/admin/master/bcn-bank/:id?",
    component: _50ae4329,
    name: "admin-master-bcn-bank-id"
  }, {
    path: "/admin/master/document-type/:id?",
    component: _227957f6,
    name: "admin-master-document-type-id"
  }, {
    path: "/admin/master/jenis-kas/:id?",
    component: _461ebf34,
    name: "admin-master-jenis-kas-id"
  }, {
    path: "/admin/master/jenis-pinjaman/:id?",
    component: _7c78230b,
    name: "admin-master-jenis-pinjaman-id"
  }, {
    path: "/admin/master/jenis-rekening/:id?",
    component: _1757ee84,
    name: "admin-master-jenis-rekening-id"
  }, {
    path: "/admin/master/jenis-simpanan/:id?",
    component: _6b46ccd0,
    name: "admin-master-jenis-simpanan-id"
  }, {
    path: "/admin/master/lembaga/:id",
    component: _3adfb046,
    name: "admin-master-lembaga-id"
  }, {
    path: "/admin/master/news-category/:id",
    component: _3f113bde,
    name: "admin-master-news-category-id"
  }, {
    path: "/admin/master/news/:id",
    component: _20a59840,
    name: "admin-master-news-id"
  }, {
    path: "/admin/master/produk/:id",
    component: _0866e91a,
    name: "admin-master-produk-id"
  }, {
    path: "/admin/master/setting-institution/:id?",
    component: _2713bcd8,
    name: "admin-master-setting-institution-id"
  }, {
    path: "/admin/master/transaction-type/:id?",
    component: _0383c136,
    name: "admin-master-transaction-type-id"
  }, {
    path: "/admin/master/vendor/:id",
    component: _f931e996,
    name: "admin-master-vendor-id"
  }, {
    path: "/admin/pengaturan/management-user/:id?",
    component: _2992d737,
    name: "admin-pengaturan-management-user-id"
  }, {
    path: "/admin/pengaturan/permission/:id",
    component: _2d48aad3,
    name: "admin-pengaturan-permission-id"
  }, {
    path: "/admin/pengaturan/role-has-permission/:id",
    component: _350ddc8a,
    name: "admin-pengaturan-role-has-permission-id"
  }, {
    path: "/admin/pengaturan/role/:id",
    component: _27edf33a,
    name: "admin-pengaturan-role-id"
  }, {
    path: "/page/cek-status-booking/:id?",
    component: _1b1907e2,
    name: "page-cek-status-booking-id"
  }, {
    path: "/auth/:registerBak?",
    component: _cc3a02b4,
    name: "auth-registerBak"
  }, {
    path: "/login-history/:id?",
    component: _dda38560,
    name: "login-history-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
